var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"text":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Regresar a reportes ")],1),_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body px-1 px-sm-5 pt-0"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-2 mb-5"},[_c('p',{staticClass:"text-h5 ma-0 pt-4 pl-4 font-weight-medium"},[_vm._v(" Parámetros ")]),_c('v-divider'),_c('v-container',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"ma-0 pa-0 text-body-1"},[_c('strong',[_vm._v(" Debes seleccionar un grado, luego dependiendo del grado seleccionado se debe seleccionar el grupo académico o un grupo específico de una especialidad. ")])])])],1),_c('v-row',{staticClass:"align-items-center mb-5"},[_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"label":"Grado","outlined":"","hide-details":"","items":_vm.grades,"item-text":"name","item-value":"id"},on:{"click":function($event){return _vm.resetReportParams()},"change":function($event){return _vm.loadTechnicalGroups()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()]}}]),model:{value:(_vm.report.params.grade_id),callback:function ($$v) {_vm.$set(_vm.report.params, "grade_id", $$v)},expression:"report.params.grade_id"}})],1)]),_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"label":_vm.areTechnicalGroupsLoading
                          ? 'Cargando grupos técnicos..'
                          : 'Grupo técnico',"loading":_vm.areTechnicalGroupsLoading,"disabled":_vm.areTechnicalGroupsLoading ||
                          !_vm.technicalGroups.length ||
                          !_vm.report.params.grade_id,"outlined":"","hide-details":"","clearable":"","items":_vm.technicalGroups,"item-text":"grade_speciality_group_formatted","item-value":"id"},on:{"change":function($event){_vm.getSubjects(), _vm.loadAcademicGroups()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.grade_speciality_group_formatted))])]):_vm._e()]}}]),model:{value:(_vm.report.params.grade_speciality_group_id),callback:function ($$v) {_vm.$set(_vm.report.params, "grade_speciality_group_id", $$v)},expression:"report.params.grade_speciality_group_id"}})],1),_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"label":_vm.areAcademicGroupsLoading
                          ? 'Cargando grupos académicos..'
                          : 'Grupo académico',"loading":_vm.areAcademicGroupsLoading,"disabled":_vm.areAcademicGroupsLoading ||
                          !_vm.academicGroups.length ||
                          !_vm.report.params.grade_id,"outlined":"","hide-details":"","clearable":"","items":_vm.academicGroups,"item-text":"grade_section_group_formatted","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.grade_section_group_formatted))])]):_vm._e()]}}]),model:{value:(_vm.report.params.grade_section_group_id),callback:function ($$v) {_vm.$set(_vm.report.params, "grade_section_group_id", $$v)},expression:"report.params.grade_section_group_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"label":_vm.subjects.isLoading
                          ? 'Cargando asignaturas'
                          : 'Asignaturas',"loading":_vm.subjects.isLoading,"disabled":!_vm.subjects.data.length,"outlined":"","hide-details":"","clearable":"","items":_vm.subjects.data,"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.loadStages()},"click":_vm.resetSelectedStage},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.report.params.subject_id.length - 1)+" seleccionados) ")]):_vm._e()]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]}}]),model:{value:(_vm.report.params.subject_id),callback:function ($$v) {_vm.$set(_vm.report.params, "subject_id", $$v)},expression:"report.params.subject_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":_vm.areStagesloading ? 'Cargando etapas..' : 'Etapa',"loading":_vm.areStagesloading,"disabled":_vm.areStagesloading ||
                          !_vm.stages.length ||
                          !_vm.report.params.grade_id,"outlined":"","hide-details":"","clearable":"","items":_vm.stages,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()]}}]),model:{value:(_vm.report.params.stage_id),callback:function ($$v) {_vm.$set(_vm.report.params, "stage_id", $$v)},expression:"report.params.stage_id"}})],1)],1)],1)],1),[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","lg":"6"}},[_c('v-card',{attrs:{"outlined":"","disabled":!_vm.canGenerateReportCard}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" estudiantes ")]),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Reporte de notas ")]),_c('v-list-item-subtitle',[_vm._v(" Reporte de notas correspondiente al grupo académico o grupo de la especialidad, etapa y asignatura seleccionados para importación al SIGES. ")])],1),_c('v-list-item-avatar',{staticClass:"rounded",attrs:{"tile":"","icon":"","size":"80","color":"success"}},[_c('v-icon',{staticClass:"ml-n1",attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","rounded":"","text":"","href":_vm.getReportCardUrl,"download":""}},[_vm._v(" Generar reporte ")])],1)],1)],1)],1)]],2)],1)],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header flex-nowrap border-0 pt-6 pb-0"},[_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"card-label"},[_c('h3',[_vm._v(" Reporte de notas ")]),_c('div',{staticClass:"text-muted text-body-1"},[_vm._v(" Generación de reporte de notas para importación al sistema del mined (SIGES) ")])])])])
}]

export { render, staticRenderFns }