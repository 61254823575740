<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>
              Reporte de notas
            </h3>
            <div class="text-muted text-body-1">
              Generación de reporte de notas para importación al sistema del
              mined (SIGES)
            </div>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->
      <div class="card-body px-1 px-sm-5 pt-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-2 mb-5">
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row
                    ><v-col>
                      <p class="ma-0 pa-0 text-body-1">
                        <strong>
                          Debes seleccionar un grado, luego dependiendo del
                          grado seleccionado se debe seleccionar el grupo
                          académico o un grupo específico de una especialidad.
                        </strong>
                      </p>
                    </v-col></v-row
                  >
                  <v-row class="align-items-center mb-5">
                    <!-- begin::select academic level filter-->
                    <v-col cols="12" sm="6" md="4" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:grade combo -->
                        <v-select
                          label="Grado"
                          outlined
                          hide-details
                          :items="grades"
                          item-text="name"
                          item-value="id"
                          v-model="report.params.grade_id"
                          @click="resetReportParams()"
                          @change="loadTechnicalGroups()"
                        >
                          <!-- begin::selected grade item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected grade item -->
                        </v-select>
                        <!-- end:grade combo -->
                      </div>
                    </v-col>
                    <!-- end::select academic level filter-->

                    <!-- begin::select technical group filter-->
                    <v-col cols="12" sm="6" md="4" class="my-2 my-md-0">
                      <!-- begin:: technicalGroups combo -->
                      <v-select
                        :label="
                          areTechnicalGroupsLoading
                            ? 'Cargando grupos técnicos..'
                            : 'Grupo técnico'
                        "
                        :loading="areTechnicalGroupsLoading"
                        :disabled="
                          areTechnicalGroupsLoading ||
                            !technicalGroups.length ||
                            !report.params.grade_id
                        "
                        outlined
                        hide-details
                        clearable
                        :items="technicalGroups"
                        item-text="grade_speciality_group_formatted"
                        item-value="id"
                        v-model="report.params.grade_speciality_group_id"
                        @change="getSubjects(), loadAcademicGroups()"
                      >
                        <!-- begin::selected technicalGroup item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{
                              item.grade_speciality_group_formatted
                            }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected technicalGroup item -->
                      </v-select>
                      <!-- end:: technicalGroups combo -->
                    </v-col>
                    <!-- end::select technical group filter-->
                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" md="4" class="my-2 my-md-0">
                      <!-- begin:: academicGroup combo -->
                      <v-select
                        :label="
                          areAcademicGroupsLoading
                            ? 'Cargando grupos académicos..'
                            : 'Grupo académico'
                        "
                        :loading="areAcademicGroupsLoading"
                        :disabled="
                          areAcademicGroupsLoading ||
                            !academicGroups.length ||
                            !report.params.grade_id
                        "
                        outlined
                        hide-details
                        clearable
                        :items="academicGroups"
                        item-text="grade_section_group_formatted"
                        item-value="id"
                        v-model="report.params.grade_section_group_id"
                      >
                        <!-- begin::selected academicGroup item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{
                              item.grade_section_group_formatted
                            }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected academicGroup item -->
                      </v-select>
                      <!-- end:: academicGroup combo -->
                    </v-col>
                    <!-- end::select section/group filter-->
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        :label="
                          subjects.isLoading
                            ? 'Cargando asignaturas'
                            : 'Asignaturas'
                        "
                        :loading="subjects.isLoading"
                        :disabled="!subjects.data.length"
                        outlined
                        hide-details
                        clearable
                        :items="subjects.data"
                        item-text="name"
                        item-value="id"
                        v-model="report.params.subject_id"
                        @change="loadStages()"
                        @click="resetSelectedStage"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ report.params.subject_id.length - 1 }}
                            seleccionados)
                          </span>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <!-- begin::select stage filter -->
                    <v-col cols="12" sm="6">
                      <v-select
                        :label="
                          areStagesloading ? 'Cargando etapas..' : 'Etapa'
                        "
                        :loading="areStagesloading"
                        :disabled="
                          areStagesloading ||
                            !stages.length ||
                            !report.params.grade_id
                        "
                        outlined
                        hide-details
                        clearable
                        :items="stages"
                        item-text="name"
                        item-value="id"
                        v-model="report.params.stage_id"
                      >
                        <!-- begin::selected stage item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected stage item -->
                      </v-select>
                    </v-col>
                    <!-- end::select stage filter-->
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->

              <!-- begin::cards with generator button for each report -->
              <template>
                <v-row>
                  <!-- begin::excel with students scores col-container-->
                  <v-col cols="12" sm="8" lg="6">
                    <!-- begin::excel with students scores-->
                    <v-card outlined :disabled="!canGenerateReportCard">
                      <!-- begin::card body -->
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            estudiantes
                          </div>
                          <v-list-item-title class="text-h5 mb-1">
                            Reporte de notas
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Reporte de notas correspondiente al grupo académico
                            o grupo de la especialidad, etapa y asignatura
                            seleccionados para importación al SIGES.
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                          class="rounded"
                          tile
                          icon
                          size="80"
                          color="success"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-microsoft-excel</v-icon
                          ></v-list-item-avatar
                        >
                      </v-list-item>
                      <!-- end::card body -->

                      <!-- begin:: card button for report generation -->
                      <v-card-actions>
                        <v-btn
                          outlined
                          rounded
                          text
                          :href="getReportCardUrl"
                          download
                        >
                          Generar reporte
                        </v-btn>
                      </v-card-actions>
                      <!-- end:: card button for report generation -->
                    </v-card>
                    <!-- end::excel with students scores-->

                    <!-- begin::fallback card if not all necesary parameters have been selected -->

                    <!-- end::fallback card if not all necesary parameters have been selected -->
                  </v-col>
                  <!-- end::excel with students scores col-container-->
                </v-row>
              </template>
              <!-- end::cards with generator button for each report -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import gradeRepository from "@/repositories/gradeRepository";
import stageRepository from "@/repositories/stageRepository";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import subjectRepository from "@/repositories/subjectRepository";

export default {
  name: "ReportCard",
  title: "Reporte de notas SIGES | GE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reportes", route: "reports" },
      { title: "Reporte de notas", route: "report_card" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Reporte de notas");
    this.loadGrades();
  },
  data() {
    return {
      stages: [],
      grades: [],
      academicGroups: [],
      technicalGroups: [],
      academicScoresSheet: [],
      areAcademicGroupsLoading: false,
      areTechnicalGroupsLoading: false,
      areStagesloading: false,
      onlyOneStudent: false,
      TERCER_CICLO_ID: 1,
      BACHILLERATO_ID: 2,
      report: {
        params: {
          grade_id: null,
          grade_section_group_id: null,
          grade_speciality_group_id: null,
          stage_id: null,
          subject_id: null,
        },
      },
      students: [],
      areStudentsLoading: false,
      subjects: {
        data: [],
        filters: {},
        isLoading: false,
      },
      customGradeSpecialityGroupsByGradeId: {
        1: [
          {
            id: 1,
            grade_speciality_group_formatted: "Todo séptimo grado",
          },
        ],
        2: [
          {
            id: 2,
            grade_speciality_group_formatted: "Todo octavo grado",
          },
        ],
        3: [
          {
            id: 3,
            grade_speciality_group_formatted: "Todo noveno grado",
          },
        ],
      },
    };
  },
  methods: {
    getSubjects() {
      if (!this.technicalGroupHasBeenSelected) return;
      this.subjects.isLoading = true;
      subjectRepository
        .getSubjectsByTechnicalGroup(
          this.report.params.grade_speciality_group_id
        )
        .then(({ data }) => {
          this.subjects.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjects.isLoading = false;
        });
    },

    //Fetching all yearly stages
    loadStages() {
      if (!this.report.params.subject_id) {
        return;
      }
      this.areStagesloading = true;
      stageRepository
        .getBySubjectId(this.report.params.subject_id)
        .then(({ data }) => {
          this.stages = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las etapas",
          });
        })
        .finally(() => {
          this.areStagesloading = false;
        });
    },

    loadTechnicalGroups() {
      if (this.academicLevelId === this.TERCER_CICLO_ID) {
        this.technicalGroups = this.customGradeSpecialityGroupsByGradeId[
          this.report.params.grade_id
        ];
        return;
      }
      this.areTechnicalGroupsLoading = true;
      technicalGroupRepository
        .getTechnicalGroupsByGrade(this.report.params.grade_id)
        .then(({ data }) => {
          this.technicalGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areTechnicalGroupsLoading = false;
        });
    },

    //Fetching all grade academic levels
    loadGrades() {
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },

    //Fetching academic groups depending on the id of the grade speciality group that's been selected
    loadAcademicGroups() {
      if (!this.technicalGroupHasBeenSelected) return;
      this.areAcademicGroupsLoading = true;
      academicGroupRepository
        .getAcademicGroupsByTechnicalGroup(
          this.report.params.grade_speciality_group_id
        )
        .then(({ data }) => {
          this.academicGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areAcademicGroupsLoading = false;
        });
    },

    resetReportParams() {
      this.report.params = {};
    },

    resetSelectedStage() {
      this.report.params.stage_id = null;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    reportsApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },
    getReportCardUrl() {
      if (!this.canGenerateReportCard) {
        return;
      }
      let url;

      const { params } = this.report;
      if (this.report.params?.grade_section_group_id) {
        url = `${this.reportsApiUrl}/scores/siges/grade-speciality-group/${params.grade_speciality_group_id}/grade-section-group/${params.grade_section_group_id}/stage/${params.stage_id}/subject/${params.subject_id}`;
        return url;
      } else {
        url = `${this.reportsApiUrl}/scores/siges/grade-speciality-group/${params.grade_speciality_group_id}/stage/${params.stage_id}/subject/${params.subject_id}`;
        return url;
      }
    },
    canGenerateReportCard() {
      return (
        this.technicalGroupHasBeenSelected &&
        this.stageHasBeenSelected &&
        this.subjectHasBeenSelected
      );
    },

    academicGroupOrTechnicalGroupBeenSelected() {
      return (
        !!this.report.params.grade_section_group_id ||
        !!this.report.params.grade_speciality_group_id
      );
    },

    technicalGroupHasBeenSelected() {
      return this.report.params.grade_speciality_group_id !== null && this.report.params.grade_speciality_group_id !== undefined;
    },
    stageHasBeenSelected() {
      return this.report.params.stage_id !== null && this.report.params.stage_id !== undefined;
    },
    subjectHasBeenSelected() {
      return this.report.params.subject_id !== null && this.report.params.subject_id !== undefined;

    },

    academicLevelId() {
      if (this.report.params.grade_id == null) {
        return;
      }

      const academicLevel = this.grades.find(
        (item) => item.id == this.report.params.grade_id
      );
      return academicLevel.academic_level.id;
    },
    filteredSubjects() {
      return this.filterData(this.report.params, this.subjects.data);
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
  watch: {
    technicalGroupHasBeenSelected(newValue, oldValue) {
      if (!newValue) {
        this.subjects.data = [];
        this.academicGroups = [];
      }
    },
  },
};
</script>

<style scoped>
.red-pdf {
  background-color: #d93025 !important;
}
</style>
